import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { AddCancelButtonn, AddLocationButtonn, LocationPopup, StyledModalV3 } from './styles';
import { ImageUrl } from '../../utils/constants';
import Waiting from '../Waiting';
const SelectLocationPopup = React.lazy(() => import('./selectLocation'));
const SelectDepartmentPopup = React.lazy(() => import('./selectDepartment'));

class ParticipantLocationPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectLocation: false,
      selectDepartment: false,
      selectedParticipant: []
    }
  }

  selectLocationPopup = () => {
    this.setState({
      selectLocation: !this.state.selectLocation
    })
  }

  selectDepartmentPopup = () => {
    this.setState({
      selectDepartment: !this.state.selectDepartment
    })
  }

  fallBack = (e) => {
    e.target.src = '/public/images/default.png'
  }

  render() {
    const { show, selectedParticipant } = this.props;
    const { selectLocation, selectDepartment } = this.state;
    return (
      <StyledModalV3 show={show} >
        <LocationPopup>
          <div className='popup-class'>
            <div className='popup-title'>Selected People (Max 50 Selection)</div>  
            <div className='inner-div'>
              {selectedParticipant.length === 0 && <div>0+</div>}
              {selectedParticipant.length > 0 && <img onError={this.fallBack} alt='img' src={selectedParticipant[0].profile_image ? `${ImageUrl}/${selectedParticipant[0].profile_image}` : "/public/images/default.png"}></img>}
              {selectedParticipant.length > 1 && <img onError={this.fallBack} alt='img' src={selectedParticipant[1].profile_image ? `${ImageUrl}/${selectedParticipant[1].profile_image}` : "/public/images/default.png"}></img>}
              {selectedParticipant.length > 2 && <img onError={this.fallBack} alt='img' src={selectedParticipant[2].profile_image ? `${ImageUrl}/${selectedParticipant[2].profile_image}` : "/public/images/default.png"}></img>}
              {selectedParticipant.length > 3 && <img onError={this.fallBack} alt='img' src={selectedParticipant[3].profile_image ? `${ImageUrl}/${selectedParticipant[3].profile_image}` : "/public/images/default.png"}></img>}
              {selectedParticipant.length > 4 && <div>{selectedParticipant.length - 4}+</div>}
              {selectedParticipant.length === 0 && <h1>Selected</h1>}
            </div>
          </div>
          <div style={{ display: 'flex', width: '50%', alignItems: 'center' }}>
            <AddLocationButtonn onClick={this.selectLocationPopup} disabled={selectedParticipant.length===0}>
              Add To Location
            </AddLocationButtonn>
            <AddLocationButtonn background='#F6B479' onClick={this.selectDepartmentPopup} disabled={selectedParticipant.length===0}>
              Add To Department
            </AddLocationButtonn>
            <AddCancelButtonn onClick={this.props.showModalPopUp}>
              Cancel
            </AddCancelButtonn>
          </div>
        </LocationPopup>
        {
          <Suspense fallback={<Waiting/>}><SelectLocationPopup hideAll={this.props.showModalPopUp} companyId={this.props.companyId} fetchEditLocationApi={this.props.fetchEditLocationApi}
            show={selectLocation} handleShowNote={this.props.handleShowNote} data={this.props.data} showModalPopUp={this.selectLocationPopup} selectedParticipant={this.props.selectedParticipant} /></Suspense>
        }
        {
          <Suspense fallback={<Waiting/>}><SelectDepartmentPopup hideAll={this.props.showModalPopUp} companyId={this.props.companyId} fetchEditDepartmentApi={this.props.fetchEditDepartmentApi}
            show={selectDepartment} handleShowNote={this.props.handleShowNote} data={this.props.departmentData} showModalPopUp={this.selectDepartmentPopup} selectedParticipant={this.props.selectedParticipant} /></Suspense>
        }
      </StyledModalV3>
    );
  }
}

ParticipantLocationPopup.propTypes = {
  show: PropTypes.bool,
  showModalPopUp: PropTypes.func,
  postAddEmployee: PropTypes.func,
  companyId: PropTypes.number,
  companyAuthDetails: PropTypes.func,
  fetchEditLocationApi: PropTypes.func,
  data: PropTypes.array,
  selectedParticipant: PropTypes.array,
  locationDetails: PropTypes.array,
  fetchEditDepartmentApi: PropTypes.func,
  handleShowNote: PropTypes.func,
  departmentData: PropTypes.func
}

export default ParticipantLocationPopup;